const unit = +window
  .getComputedStyle(document.documentElement)
  .getPropertyValue('--unit')
  .replace('px', '');

function smoothScroll(e) {
  e.preventDefault();

  const href = this.getAttribute('href');
  const offsetTop = document.querySelector(href).offsetTop - unit;

  window.scrollTo({
    top: offsetTop,
    behavior: 'smooth',
  });
}

function createAnchorLink(link, target) {
  const anchorName = link.dataset.anchor;
  const anchorLink = link.getAttribute('id');
  const anchor = document.createElement('a');
  const anchorLinksContainer = target.querySelector('.anchor-nav__links');

  anchor.setAttribute('href', `#${anchorLink}`);
  anchor.setAttribute('aria-label', `Anchor link to ${anchorName}`);
  anchor.innerText = anchorName;

  anchorLinksContainer.append(anchor);

  anchor.addEventListener('click', smoothScroll);
}

export default function createAnchorNav() {
  const anchorBlocks = document.querySelectorAll('[data-anchor]');

  if (anchorBlocks.length > 0) {
    const anchorNav = document.querySelector('.anchor-nav');

    // Create anchor links for evert anchor block
    anchorBlocks.forEach((link) => createAnchorLink(link, anchorNav));

    // Unhide anchor nav
    anchorNav.classList.add('enabled');
  }
}

document.addEventListener('DOMContentLoaded', function () {
  createAnchorNav();
});

if (window.acf) {
  window.acf.addAction('render_block_preview', createAnchorNav);
}

jQuery('.sub-nav p').click(function () {
  jQuery(this).next('.links').toggleClass('open');
});
